<template>
  <BasePageSection
    :bg-image="['xs', 'sm'].includes($mq) ? false : bgImg"
    :bg-color="['xs', 'sm'].includes($mq) ? '#ff4c3d' : false"
    full-bleed-bg
  >
    <h2 class="white--text text-xs-center">Instagram</h2>
    <VLayout wrap>
      <RestoInstagram
        :token="getIgAccessToken"
        :count="['xs', 'sm'].includes($mq) ? 4 : 8"
        :mediatypes="['IMAGE', 'VIDEO', 'CAROUSEL_ALBUM']"
        class="layout row wrap"
      >
        <template #feeds="{ feed }">
          <VFlex
            grow
            xs12
            sm6
            md3
            :style="{
              'padding-top': imgPadding[$mq],
              position: 'relative',
            }"
          >
            <a
              class="img-elem"
              :style="{
                top: 0,
                left: 0,
                height: 'calc( 100% - 20px )',
                width: 'calc( 100% - 20px )',
                margin: '10px',
                position: 'absolute',
                'background-image': `url(${
                  feed.media_type === 'VIDEO'
                    ? feed.thumbnail_url
                    : feed.media_url
                })`,
                'background-size': 'cover',
                'background-position': 'center center',
                'text-align': 'center',
              }"
              :href="feed.permalink"
              target="_blank"
            >
              <VIcon
                v-if="feed.media_type === 'VIDEO'"
                color="white"
                large
                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"
                >play_circle_filled
              </VIcon>
            </a>
            <!-- <img :src="feed.images.standard_resolution.url" /> -->
          </VFlex>
        </template>
        <template #error="{ error }">
          <VFlex v-if="!!error" text-xs-center sm6 mx-auto>
            <div>The Instagram feed couldn't be loaded.</div>
            <div v-if="loggedIn" class="mt-4">
              You're seeing this message because you are logged in to Resto. If
              reloading the page doesn't fix this issue please contact support.
              <div class="mt-2">Error message: "{{ error }}"</div>
            </div>
          </VFlex>
        </template>
      </RestoInstagram>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'

import getSiteAsset from '@utils/get-site-asset'
import RestoInstagram from '@components/RestoInstagram'

export default {
  name: 'SectionInstagram',
  components: { RestoInstagram },
  props: {},
  data() {
    return {
      imgPadding: {
        xs: '100%',
        sm: '100%',
        md: '50%',
        lg: '25%',
        xl: '25%',
      },
    }
  },
  computed: {
    ...mapGetters('site', ['getIgAccessToken', 'getSiteId']),
    ...mapGetters('pages', ['getPageContent']),
    ...mapGetters('auth', ['loggedIn']),
    bgImg() {
      return (
        this.getPageContent('instagram_bg_img') ||
        getSiteAsset(`site-${this.getSiteId}-instagram-bg.jpg`)
      )
    },
  },
}
</script>

<style lang="scss">
@import '@design';

.img-elem {
  border: solid 6px white;

  @media screen and (min-width: 600px) {
    border: solid 10px $color-bluegiant-blue;
  }
}
</style>
